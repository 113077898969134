

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //$.DVCore.components.DVPopup.init('.js-fancybox');

          }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'zwangerschapskalender': {
      init: function() {
        // Opens accordion automatically if an accordion target is accessed from another page
    // Assumes the accordion-group is the target linked to

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on('load', function () {
    //$.DVCore.components.DVStickyBlock.init('.js-sticky-block');
    $.DVCore.components.DVHeaderFullscreen.init($('#fullscreen'));
    $.DVCore.components.DVHamburgers.init('#hamburgerTrigger');
    // initialization of popups
    // $.DVCore.components.DVFancyBox.init('.js-fancybox');
    // initialization of slick carousel
    // $.DVCore.components.DVSlickCarousel.init('.js-slick-carousel');

  });

  $(document).click(function(e) {
	if (!$(e.target).is('.list-group')) {
    	$('.collapse').collapse('hide');
    }
});

  $(document).on('ready', function () {

    // function openAnchorAccordion() {
    //     if (window.location.hash) {
    //         var $target = $('body').find(window.location.hash);
    //         if ($target.hasClass('accordion-group')) {
    //             var $targetAccordion = $target.find('.collapse');
    //             $targetAccordion.collapse('show');
    //         }
    //     }
    // }
    //
    // openHashAccordion();
    //
    // $("body").on("click", "a", openHashAccordion);

    $('.partners .owl-carousel').owlCarousel({
        stagePadding: 50,
        loop: true,
        margin:0,

         center: true,
        nav: true,
        navText: [
            '<i class="fa fa-angle-left" aria-hidden="true" style=\"color:#84b48e;\"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true" style=\"color:#84b48e;\"></i>'
        ],
        navContainer: '.partners .custom-nav-partners',
        responsiveClass:true,

        responsive:{
            0:{

         items:1,
         nav:true
     },

            320:{

         items:1,
         nav:true

            },
            600:{
                items: 3
            },
            1000:{
                items: 4
            }
        }
    });

$('.carousel .owl-carousel').owlCarousel({
    stagePadding: 50,
    loop: true,
    margin:0,
    nav:true,
     center: true,
    nav: true,
    navText: [
        '<i class="fa fa-angle-left" aria-hidden="true" style=\"color:#84b48e;\"></i>',
        '<i class="fa fa-angle-right" aria-hidden="true" style=\"color:#84b48e;\"></i>'
    ],
    navContainer: '.carousel .custom-nav',
    responsiveClass:true,

    responsive:{
      0:{
         items:1,
         nav:true
     },
        320:{
            items: 2
        },
        600:{
            items: 2
        },
        1000:{
            items: 4
        }
    }
});




      // initialization of slick carousel
      //$.DVCore.components.DVSlickCarousel.init('.js-slick-carousel');
      $.DVCore.components.DVHeaderFullscreen.init($('#fullscreen'));
      $.DVCore.components.DVHamburgers.init('#hamburgerTrigger');
    // initialization of sticky blocks
    //$.DVCore.components.DVStickyBlock.init('.js-sticky-block');

     // initialization of popups
     $.DVCore.components.DVUnfold.init($('[data-unfold-target]'));

     $.DVCore.components.DVUnfold.init($('[data-unfold-target]'), {
       afterOpen: function () {
         $(this).find('input[type="search"]').focus();
       }
     });

   });
  // JavaScript to be fired on all pages, after page specific JS is fired


})(jQuery); // Fully reference jQuery after this point.
